import React from 'react';
import Select from 'react-select';
import { Link } from 'gatsby';
import Checkbox from '../Form/Checkbox';

export default class HomeSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            areas: [
                { label: "Вся Україна", value: "all" },
                { label: "Київ", value: "Київ" },
                { label: "Севастополь", value: "Севастополь" },
                { label: "Вінницька", value: "Вінницька" },
                { label: "Волинська", value: "Волинська" },
                { label: "Дніпропетровська", value: "Дніпропетровська" },
                { label: "Донецька", value: "Донецька" },
                { label: "Житомирська", value: "Житомирська" },
                { label: "Закарпатська", value: "Закарпатська" },
                { label: "Запорізька", value: "Запорізька" },
                { label: "Івано-Франківська", value: "Івано-Франківська" },
                { label: "Київська", value: "Київська" },
                { label: "Кіровоградська", value: "Кіровоградська" },
                { label: "Луганська", value: "Луганська" },
                { label: "Львівська", value: "Львівська" },
                { label: "Миколаївська", value: "Миколаївська" },
                { label: "Одеська", value: "Одеська" },
                { label: "Полтавська", value: "Полтавська" },
                { label: "Рівненська", value: "Рівненська" },
                { label: "Сумська", value: "Сумська" },
                { label: "Тернопільська", value: "Тернопільська" },
                { label: "Харківська", value: "Харківська" },
                { label: "Херсонська", value: "Херсонська" },
                { label: "Хмельницька", value: "Хмельницька" },
                { label: "Черкаська", value: "Черкаська" },
                { label: "Чернівецька", value: "Чернівецька" },
                { label: "Чернігівська", value: "Чернігівська" },
            ],
            filters: [
                {
                    title: "Є потенційна недоплата",
                    value: "is_expected_2_plus_times_gr_than_payment",
                }, { 
                    title: "Некоректний код ЄДРПОУ орендаря",
                    value: "is_tennant_code_bug", 
                }, {
                    title: "Некоректний код ЄДРПОУ балансоутримувача",
                    value: "is_owner_code_bug",
                }, {
                    title: "Ризик заниженої оціночної вартості",
                    value: "is_less_avg_price_m2",
                }, {
                    title: "Є уточнення до ціни",
                    value: "is_price_footnotes",
                }, {
                    title: "Пільгова ставка для бізнесу",
                    value: "is_tennant_business_with_cheap_rent"
                }, {
                    title: "Оренда бізнесом під потреби ГО",
                    value: "is_tennant_ngo_business"
                }, {
                    title: "Тривале подовження у 2019-2020 рр.",
                    value: "is_big_longations_after_2019"
                }, {
                    title: "Багато ГО за однією адресою",
                    value: "is_more_than_one_ngo_agreement_per_object"
                }
            ],
            selectedArea: 'Київська',
            selectedFilters: [],
            queryGen: null
        }

        this.updateArea = this.updateArea.bind(this);
        this.selectFilters = this.selectFilters.bind(this);
        this.updateQry = this.updateQry.bind(this);
    }

    updateArea(value) {

        this.setState({
            selectedArea: value && value.value
        }, () => this.updateQry());
    }

    selectFilters(value) {
        const selectedFilters = this.state.selectedFilters;
        const findIdx = selectedFilters.indexOf(value);

        if (findIdx > -1) {
            selectedFilters.splice(findIdx, 1);
        } else {
            selectedFilters.push(value);
        }

        this.setState({
            selectedFilters: selectedFilters, 
        }, () => this.updateQry());
    }

    updateQry() {
        const selectedFilters = this.state.selectedFilters;
        const filtersQry = selectedFilters.length ? `risks=${selectedFilters.map(item => item).join(',')}` : '';

        const selectedArea = this.state.selectedArea;
        const areaQry = selectedArea ? `region=${selectedArea}` : '';

        const outputQry = `${areaQry}${areaQry && filtersQry ? '&' + filtersQry : filtersQry}`;

        this.setState({
            queryGen: outputQry ? outputQry : ''
        });
    }

    componentDidMount() {
        this.updateQry();
    }

    render() {
        return (
            <div className="view-home__search view-form">
                <Select 
                    className="view-form__select"
                    classNamePrefix="view-form__select"
                    placeholder="Оберіть регіон"
                    options={this.state.areas}
                    onChange={this.updateArea}
                    isSearchable={false}
                    defaultValue={{ label: "Київська", value: "Київська" }}
                />
    
                <div className="view-home__search__description">
                    Оберіть фільтри пошуку:
                </div>
                <div className="view-home__search__options">
                    {this.state.filters.map((item, key) => (
                        <Checkbox 
                            key={key}
                            value={item.value}
                            checked={this.state.selectedFilters.find(select => select === item.value)}
                            onChange={() => {this.selectFilters(item.value)}}
                            title={item.title}
                        />
                    ))}
                </div>
                <div className="view-form__actions">
                    <Link to={`/map${this.state.queryGen ? '?' + this.state.queryGen : ''}`} className="button button--primary">
                        Шукати
                    </Link>
                </div>
            </div>
        );
    }
}