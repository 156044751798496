import React from 'react';
import HomeSearch from './HomeSearch';
import HeaderImage from './homeHeaderImage.svg';

export default function HomeHeader() {
    return (
        <div className="view-home__header">
            <div className="view-container view-home__header__container">
                <div className="view-home__header__content">
                    <h1 className="view-title view-title--oversize">
                        Знайти <span>порушення</span> поряд
                    </h1>
                    <HomeSearch />
                </div>
                <div className="view-home__header__image">
                    <HeaderImage />
                </div>
            </div>
        </div>
    );
}