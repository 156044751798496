import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import '../Blog/blogList.scss';

const HomeBlog = () => {

    return(
        <section className="view-section view-section--bcg view-home__blog">
            <div className="view-container">
                <h2 className="view-title view-title--h2 ta-center">
                    Блог
                </h2>

                <div className="view-blog-list">
                    <StaticQuery
                        query={graphql`
                            {
                                allMdx(limit: 3, sort: {fields: frontmatter___date, order: DESC}) {
                                    edges {
                                        node {
                                            id
                                            frontmatter {
                                                title
                                                summary
                                                date(formatString: "DD-MM-YYYY")
                                                thumbnail {
                                                    childImageSharp {
                                                        fluid {
                                                            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                                                            ...GatsbyImageSharpFluid
                                                        }
                                                    }
                                                }
                                            }
                                            fields {
                                                slug
                                            }
                                        }
                                    }
                                }
                            }
                        `}
                        render={data => {
                            const {edges: posts} = data.allMdx;
                            return (
                                posts.map(({ node: post }) => (
                                    <div key={post.id} className="view-blog-list__item">
                                        <Link to={post.fields.slug} className="view-blog-list__item__image">
                                            <Img 
                                                fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
                                                alt="#"/>
                                        </Link>
                                        <time className="view-blog-list__item__date">
                                            {post.frontmatter.date}
                                        </time>
                                        <Link to={post.fields.slug} className="view-blog-list__item__title">
                                            {post.frontmatter.title}
                                        </Link>
                                        <div className="view-blog-list__item__summary">
                                            {post.frontmatter.summary}
                                        </div>
                                    </div>
                                ))
                            )
                        }}
                    ></StaticQuery>
                </div>

                <div className="ta-center">
                    <Link to="/posts/" className="button button--link">
                        Читати більше
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default HomeBlog;