import React from 'react';
import HomeHeader from './HomeHeader';
import HomeAbout from './HomeAbout';
import HomeBlog from './HomeBlog';
import './styles.scss';

export default function Home() {
    return (
        <div className="view-home">
            <HomeHeader />
            <HomeAbout />
            <HomeBlog />
        </div>
    );
}