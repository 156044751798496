import React from 'react';

const Checkbox = ({ checked, value, title, ...props }) => (
    <label className="view-form__radio">
        <input 
            type="checkbox" 
            value={value} 
            checked={checked}
            {...props}
        />
        <pre></pre>
        { title }
    </label>
);

export default Checkbox;