import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import Home from '../components/Home/Home';

class Analytics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingObjects: false,
      isLoadingObjectsError: false,
      isLoadingObjectData: false,
      isLoadingObjectDataError: false,
      zeroObjectsReturned: false,
      zeroAgreementsReturned: false,
      markers: [],
      object_id: null,
      objects: [],
      agreements: [],
      is_owner_code_bug: true,
      is_tennant_code_bug: true,
      is_less_avg_price_m2: true,
      is_expected_2_plus_times_gr_than_payment: true,
      is_price_footnotes: true,
      is_tennant_business_with_cheap_rent: true,
      is_tennant_ngo_business: true,
      is_big_longations_after_2019: true,
      is_more_than_one_ngo_agreement_per_object: true,
      region: "",
    };
  }


  render() {

    return (
      <Layout>
        <SEO title="Аналітика" />

        <Home />
      </Layout>
    );
  }
}

export default Analytics;
