import React from 'react';
import image from './homeAboutImage.png';

export default function HomeAbout() {
    return(
        <section id="about" className="view-section view-home__about">
            <div className="view-container view-home__about__wrapper">
                <div className="view-home__about__image">
                    <img src={image} alt="Про нас" />
                </div>

                <div className="view-home__about__caption">
                    <h2 className="view-title view-title--h2">
                        Про нас
                    </h2>
                    <div className="view-text">
                        <p>
                            <b>Ми - Громадська організація "Гідно"</b>,
                            фіналісти <a href="https://odc.in.ua/" target="_blank" rel="noreferrer">Open Data Challenge 2020</a> та
                            одні з переможців конкурсу малих грантів на підтримку проєктів
                            громадянського суспільства на основі відкритих державних даних - Opendatago. 
                            Ми розробляємо даний ресурс із метою підвищення ефективності управління державним майном. 
                            Ця сфера, на відміну від аналізу публічних закупівель чи декларацій, 
                            рідко потрапляла під приціли журналістських розслідувань. 
                        </p>
                        <p>
                            Зараз практично відсутні інструменти із аналітики державного майна, 
                            які дозволяли би вимірювати ефективність чи знаходити махінації. 
                            А в управлінні державним майном усе не зовсім чисто. 
                            Радше навпаки, іноді там відбувається такий хаос, 
                            що мало хто хоче цим займатись.
                        </p>
                    </div>
                    <div className="view-home__about__call">Що ж, challenge accepted!</div>
                </div>
            </div>
        </section>
    );
}